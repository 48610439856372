export const IMAGES = {
  alpakids: require('./alpakids.png').default,
  arkaader: require('./arkaader_logo.jpg').default,
  delfi: require('./delfi.png').default,
  digar: require('./digar.png').default,
  digiraamat: require('./digiraamat.png').default,
  drops: require('./drops.png').default,
  eesti_k_tasemetestid: require('./eesti_k_tasemetestid.png').default,
  eesti_keel: require('./eesti_keel.png').default,
  ehtl: require('./ehtl.png').default,
  eis: require('./eis.png').default,
  eki: require('./eki.png').default,
  ekkm: require('./ekkm.png').default,
  ekoolikott: require('./ekoolikott.png').default,
  ekrk: require('./ekrk.png').default,
  ellu: require('./ellu.png').default,
  err_arhiiv: require('./err_arhiiv.png').default,
  err_logo: require('./err_logo.png').default,
  etera: require('./etera.png').default,
  fons: require('./fons.png').default,
  glosbe: require('./glosbe.png').default,
  go3: require('./go3.png').default,
  google_translate: require('./google_translate.png').default,
  grafo: require('./grafo.png').default,
  integratsioon_sa: require('./integratsiooni_sa.png').default,
  jupiter: require('./jupiter.png').default,
  kaanuk: require('./kaanuk.png').default,
  kanal2: require('./kanal2.png').default,
  keelekliki_logo: require('./keelekliki_logo.png').default,
  keeleoppija_sonaveeb: require('./keeleoppija_sonaveeb.png').default,
  keeleveeb: require('./keeleveeb.png').default,
  korp: require('./korp.png').default,
  kreuzwaldisajand: require('./kreutzwaldisajand.png').default,
  krisostomus: require('./krisostomus.png').default,
  kukuraadio: require('./kukuraadio.png').default,
  lasteekraan: require('./lasteekraan.png').default,
  leplanner: require('./Leplanner.png').default,
  multikey: require('./multikey.png').default,
  neurotolge: require('./neurotolge.png').default,
  ohtuleht: require('./ohtuleht.png').default,
  opiq: require('./opiq.png').default,
  pghtl: require('./pghtl_img.png').default,
  podcastid: require('./podcastid.png').default,
  poogen: require('./poogen.png').default,
  postimees: require('./postimees.png').default,
  r4: require('./R4.png').default,
  raadio2: require('./raadio2.png').default,
  retkeestisse: require('./retkeestisse.png').default,
  sirp: require('./sirp.png').default,
  skell: require('./skell.png').default,
  sky: require('./sky.png').default,
  sonaveeb: require('./sonaveeb.png').default,
  speakly: require('./speakly.png').default,
  tahela: require('./tahela.png').default,
  taltech: require('./taltech_logo.png').default,
  tandm: require('./tandm.png').default,
  taskutark: require('./taskutarga_logo.png').default,
  tilde: require('./tilde.png').default,
  tu: require('./tu_logo.png').default,
  tv3play: require('./tv3play.png').default,
  vikerraadio: require('./vikerraadio.png').default,
  walktalk: require('./walktalk.png').default,
  yleilmakool: require('./yleilmakool.png').default

};
