export const TRANSLATIONS_ET = {
  admin_panel_database_status: 'Andmebaasi staatus',
  admin_panel_metrics: 'Mõõdikud (alates viimasest serveri taaskäivitusest)',
  admin_panel_metrics_average: 'Keskmine analüüs',
  admin_panel_metrics_count: 'Analüüse kokku',
  admin_panel_metrics_internal_server_error: 'Sisemisi töötlemata serveri vigu',
  admin_panel_metrics_max: 'Pikim analüüs',
  admin_panel_metrics_no_data: 'Andmed puuduvad',
  admin_panel_metrics_seconds: 'sekundit',
  admin_panel_texts_to_review: 'Ülevaatust ootavaid tekste',
  analyse_button: 'Analüüsi',
  applied_filters: 'Rakendatud filtrid',
  back: 'EELMINE',
  beginning: 'algus',
  collocates_filename: 'naabersõnad',
  collocates_graph: 'Joonis',
  collocates_graph_filename: 'joonis',
  collocates_graph_modal_tooltip: 'Kuva naabersõnad joonisel',
  collocates_graph_slider_title: 'Määra, kas tahad joonisel näha vähem või rohkem naabersõnu',
  collocates_graph_tooltip: 'Joonisel kujutatakse valitud sõna naabersõnu, nende olulisust ja paiknemist otsisõna suhtes. Naabersõnad asuvad otsisõnast vasakul, paremal või keskel vastavalt sellele, kas need esinevad tekstis pigem otsisõna ees, järel või võrdselt mõlemal pool. Suurema skooriga naabersõnad on tähistatud suurema mummuga. Vaikimisi näidatakse kuni 30 olulisemat naabersõna, aga nende arvu saab soovi korral muuta.',
  common_about: 'Keskkonnast',
  common_admin_panel: 'Administraatorile',
  common_analysis_options: 'Analüüsi valikud',
  common_articles: 'Publikatsioonid',
  common_audiovisual_media: 'Audiovisuaalne meedia',
  common_author_data: 'Autori andmed',
  common_by_base_form: 'algvormi alusel',
  common_by_word_form: 'sõnavormi alusel',
  common_case_sensitive: 'tõstutundlik',
  common_clusters: 'Mustrileidja',
  common_conferences_and_workshops: 'Konverentsid ja töötoad',
  common_corrector: 'Tekstihindaja',
  common_definition: 'Tähendus (Sõnaveeb)',
  common_dictionaries: 'Sõnastikud',
  common_donate_text: 'Loovuta oma tekst',
  common_download: 'Laadi alla',
  common_enter_search_word: 'Sisesta otsisõna',
  common_estonian_language_corpora: 'Eesti keele korpused',
  common_estonian_texts: 'Eestikeelsed tekstid',
  common_excel_sheet_name: 'Tabel',
  common_for_teachers: 'Abiks õpetajale',
  common_form: 'Vorm',
  common_graduation_papers: 'Lõputööd',
  common_grants: 'Grandid',
  common_header_frequency: 'Sagedus',
  common_header_number: 'Jrk',
  common_header_percentage: 'Osakaal',
  common_hello: 'Tere',
  common_introduction: 'Tutvustus',
  common_learning_environments_and_courses: 'Keeleõppekeskkonnad ja kursused',
  common_learning_resources: 'Keeleõppevara',
  common_lemma: 'Algvorm',
  common_lemmas: 'Algvormid',
  common_license_cc_by_4_0: 'CC BY 4.0',
  common_links: 'Lingikogu',
  common_login_for_admins: 'Sisenemine administraatoritele',
  common_neighbouring_words: 'Naabersõnad',
  common_other: 'Muu',
  common_people: 'Töötajad',
  common_publications: 'Üllitised',
  common_publish_your_text: 'Teksti loovutamine',
  common_query: 'Tekstipäring',
  common_search: 'Otsi',
  common_sentences: 'Lauseid',
  common_syllables: 'Silbid',
  common_text_data: 'Teksti andmed',
  common_text_data_field_of_research: 'Valdkond',
  common_text_data_field_of_research_biosciences_and_environment: 'Bio- ja keskkonnateadused',
  common_text_data_field_of_research_culture_and_society: 'Ühiskonnateadused ja kultuur',
  common_text_data_field_of_research_health: 'Terviseuuringud',
  common_text_data_field_of_research_natural_sciences_and_engineering: 'Loodusteadused ja tehnika',
  common_text_data_type_L2_olympiade_creative_writing: 'K2 olümpiaaditöö loovkirjutis',
  common_text_data_type_L2_proficiency_examination: 'K2 riiklik eksamitöö',
  common_text_data_type_creative_writing: 'Loovkirjutis',
  common_text_data_type_examination: 'Eksamitöö',
  common_text_data_type_exercise: 'Harjutus',
  common_text_data_type_opinion_piece: 'Arvamuslugu',
  common_text_data_type_test: 'Kontrolltöö/test',
  common_text_data_type_translation: 'Tõlge',
  common_tools: 'Tekstid & tööriistad',
  common_translation: 'Tõlge (Google Translate)',
  common_translation_tools: 'Tõlkerakendused',
  common_us: 'Meist',
  common_view: 'Kuva',
  common_word_analyser: 'Sõnaanalüsaator',
  common_word_analysis: 'Sõnaanalüüs',
  common_word_in_context: 'Sõna kontekstis',
  common_wordlist: 'Sõnaloend',
  common_words: 'Sõnu',
  common_words_in_text: 'Tekstisõnad',
  common_wordtype: 'Sõnaliik',
  concordances: 'Kasutuskontekstid',
  concordances_before_and_after_selected_word: 'enne ja pärast valitud sõna',
  concordances_case_sensitive_hover: 'Vaikimisi ei arvestata otsisõna suurt või väikest algustähte, nt "eesti" võimaldab leida nii "eesti" kui ka "Eesti" kasutuskontekstid. Märgi kasti linnuke, kui soovid ainult väike- või suurtähega algavaid vasteid.',
  concordances_following_context: 'Järgnev kontekst',
  concordances_keyword_lemmatization_warning: 'Otsisõna "{{initialKeywordResult}}" vasteid ei leitud. Kasutasime automaatset algvormi tuvastust ja otsisime sõna "{{lemmatizedKeywordResult}}" vormide kasutusnäiteid.',
  concordances_preceding_context: 'Eelnev kontekst',
  concordances_search_word: 'Otsisõna',
  concordances_sentences: 'lauset',
  concordances_view: 'Kuva',
  concordances_words: 'sõna',
  cookie_acknowledgement_snackbar_button: 'Sain aru',
  cookie_acknowledgement_snackbar_content_1: 'ELLE veebilehel kasutatakse küpsiseid, mis on vajalikud lehe toimimiseks.',
  cookie_acknowledgement_snackbar_content_2: 'Veebilehe kasutades nõustud küpsiste hoiustamisega oma seadmes.',
  corpus_donation_text: 'Aita kaasa keeleuurimisele ja loovuta oma tekst meie tekstikogusse. Ootame nii eesti keele õppijate kui ka emakeelekõnelejate tekste.',
  corpus_text_count: '12 000+',
  corpus_texts: 'teksti analüüsimiseks',
  corpus_word_count: '3 000 000+',
  corpus_words: 'sõnast koosnev tekstikogu',
  end: 'lõpp',
  error_404_page_not_found: '404: lehte ei leitud',
  error_file_upload_failed: 'Failide üleslaadimine ebaõnnestus.',
  error_forbidden: 'Sul puudub õigus selle toimingu sooritamiseks.',
  error_generic_server_error: 'Toiming ebaõnnestus. Palun proovi hiljem uuesti.',
  error_id_code_missing: 'Sisenemine ebaõnnestus. ELLE-sse sisenemiseks salvesta esmalt oma HarID kontole isikukood ning proovi uuesti.',
  error_login_failed: 'Sisenemine ebaõnnestus. Palun proovi hiljem uuesti.',
  error_mandatory_field: 'Väli on kohustuslik.',
  error_no_matching_keywords: 'Tekstist ei leitud otsisõna. Muuda analüüsi valikuid ja proovi uuesti.',
  error_page_not_found: 'Lehte ei leitud',
  error_query_no_subcorpus_picked: 'Vali vähemalt üks alamkorpus.',
  error_too_many_requests: 'Oled teinud lühikese aja jooksul liiga palju päringuid. Palun oota hetk ja proovi uuesti.',
  error_unauthorized: 'Jätkamiseks palun logi sisse.',
  error_unsupported_mimetype: 'Üks või mitu üles laaditud faili ei ole õiges vormingus. Palun kontrolli faile ja proovi uuesti.',
  exit: 'LÕPETA',
  filter_by_syllable: 'Filtreeri silbi asukoha järgi sõnas',
  filter_by_word_form: 'Filtreeri sõnavormi järgi',
  filter_by_word_type: 'Filtreeri sõnaliigi järgi',
  find_out_more: 'Uuri lähemalt',
  footer_contact: 'Kontakt',
  footer_references: 'Viited',
  footer_references_elle_github: 'ELLE GitHub repositoorium',
  footer_references_elle_license: 'ELLE litsents',
  footer_references_evkk: 'Eesti vahekeele korpus (EVKK)',
  footer_references_evkk_license: 'EVKK litsents',
  footer_tools: 'Tööriistad',
  forward: 'JÄRGMINE',
  gram_anal_filename: 'gram_analyys',
  hero_main_text_highlighted: 'Eesti keele õppe ja analüüsi keskkond',
  hero_main_text_not_highlighted: ' õppijatele, õpetajatele ja teadlastele.',
  hero_tools_button: 'Tutvu tööriistadega',
  homepage_box_clusters_content: 'saad tekstist leida tüüpilisemad sõnajärjendid.',
  homepage_box_clusters_title: 'Mustrileidjaga',
  homepage_box_concordances_content: 'rakendusega saad näha, kuidas sõnu tekstis kasutatakse.',
  homepage_box_concordances_title: 'Sõna kontekstis',
  homepage_box_corrector_content: 'saad lasta oma kirjutist hinnata. Vaata, mida arvab robot sinu teksti õigekirjast ja keerukusest ning millisele keeleoskustasemele see vastab.',
  homepage_box_corrector_title: 'Tekstihindajaga',
  homepage_box_neighbouring_words_content: 'rakendusega saad leida sõnad, mis esinevad sageli lähestikku.',
  homepage_box_neighbouring_words_title: 'Naabersõnade',
  homepage_box_query_content: 'kaudu saad otsida materjale EVKK tekstikogust, lähtudes autori ja teksti andmetest. Soovi korral saad ka oma kirjutise uurimistööks loovutada.',
  homepage_box_query_title: 'Tekstipäringu',
  homepage_box_word_analysis_content: 'saad tekstist leida silpe, algvorme ja grammatilisi vorme.',
  homepage_box_word_analysis_title: 'Sõnaanalüsaatoriga',
  homepage_box_wordlist_content: 'abil saad vaadata, millised sõnad tekstis sagedamini esinevad.',
  homepage_box_wordlist_title: 'Sõnaloendi',
  homepage_services_title: 'Mida ELLE keskkond võimaldab?',
  homepage_summary_text_1: 'Andmepõhist keeleõppimist, kus õppija saab keelematerjali põhjal ise reegleid tuletada või õpitut kinnistada.',
  homepage_summary_text_2: 'Toimetada nii õpingute kui ka töö käigus kirjutatud tekste, näiteks korrigeerida kodutöid ja lihtsustada ametitekste.',
  homepage_summary_text_3: 'Kontrollida õpilaste kirjalikke töid, valida ja koostada õppevara lähtudes eri taseme õppijate tüüpilisest keelekasutusest.',
  homepage_summary_text_4: 'Analüüsida tekstide sisu ja keelekasutust uurimistöös, näiteks keele-, kultuuri- ja meediauuringutes.',
  homepage_summary_title: 'Mida ELLE keskkond toetab?',
  homepage_title: 'ELLE - Eesti keeleõppe ja -analüüsi keskkond õppijale, õpetajale ja teadlasele',
  homepage_title_explainer: 'ELLE pakub tekstikogusid ja tekstianalüüsi vahendeid eesti keele õppimiseks ja õpetamiseks nii teise keelena kui ka emakeelena, samuti keeleuurimiseks. Päringu abil saad leida huvipakkuvaid tekste Eesti vahekeele ehk õppijakeele korpusest (EVKK), mis sisaldab peamiselt eesti keele kui teise keele õppijate kirjutisi, aga ka emakeelse keelekasutuse näiteid ja akadeemilisi tekste. Tööriistad võimaldavad analüüsida ka omavalitud tekstide sõnavara ja grammatikat, kontrollida nende keerukust ja korrektsust ning hinnata keeleoskustaset.',
  input_field_tutorial_text: 'Siin kastis on võimalik sõnadel klõpsata. Klõpsates hkuvatakse valitud sõnad kollase taustaga.',
  lemmas_filename: 'algvormid',
  lemmas_header_wordforms: 'Sõnavormid',
  links_academic_texts: 'Teadustekstid',
  links_language_courses: 'Kursused',
  links_language_learning_environments: 'Keeleõppekeskkonnad',
  links_language_learning_games: 'Keeleõppemängud',
  links_language_learning_materials_and_exercises: 'Õppematerjalid ja harjutused',
  links_literature: 'Kirjandus',
  links_media_texts: 'Meediatekstid',
  links_radio_audio_podcasts: 'Raadio, audio, taskuhäälingud',
  links_series_films_videos: 'Sarjad, filmid, videod',
  middle: 'keskel',
  navbar_logout: 'Logi välja',
  neighbouring_words_case_sensitive_hover: 'Vaikimisi ei arvestata otsisõna suurt või väikest algustähte, nt "eesti" võimaldab leida nii "eesti" kui ka "Eesti" naabersõnad. Märgi kasti linnuke, kui soovid ainult väike- või suurtähega algavaid vasteid.',
  neighbouring_words_choose_statistic_measure: 'Vali valem',
  neighbouring_words_collocation: 'Naabersõna',
  neighbouring_words_frequency_in_text: 'Sagedus tekstis',
  neighbouring_words_keyword_lemmatization_warning: 'Otsisõna "{{initialKeywordResult}}" vasteid ei leitud. Kasutasime automaatset algvormi tuvastust ja otsisime sõna "{{lemmatizedKeywordResult}}" naabersõnu.',
  neighbouring_words_number_of_co_occurrences: 'Kooskasutuste arv',
  neighbouring_words_percentage_in_text: 'Osakaal tekstis',
  neighbouring_words_score: 'Skoor',
  neighbouring_words_search_for_neighbouring_words: 'Otsi naabersõnu',
  neighbouring_words_search_within_preceding_and_following_words: 'eelneva ja järgneva sõna piires',
  neighbouring_words_statistic_measure_hover: 'Pakume naabersõnade leidmiseks kolme valemit, mis annavad mõnevõrra erinevaid tulemusi. logDice ei sõltu teksti pikkusest ja sobib seega kõige paremini mahukate tekstikogude analüüsimiseks. Üksikute tekstide puhul on logDice\'i ja T-skoori alusel saadud tulemused üsna sarnased. MI-skoor toob paremini esile harvaesinevaid sõnaühendeid, kuid määrab oluliste naabersõnade hulka ka rohkem sisutühje sõnu, mis esinevad paljude sõnade lähiümbruses.',
  neighbouring_words_statistic_measure_logdice: 'logDice',
  neighbouring_words_statistic_measure_mi_score: 'MI-skoor',
  neighbouring_words_statistic_measure_t_score: 'T-skoor',
  page_title_homepage: 'Avaleht',
  pagination_go_to_page: 'Mine lehele:',
  pagination_page: 'Leht',
  publish_your_text_author_data_other_languages_tooltip: 'Sisesta keeled komaga eraldatult, märkides esimesena keele, mida kõige paremini oskad',
  publish_your_text_content: 'Tekst',
  publish_your_text_content_helper_text: 'Laadi tekst üles või kirjuta see siia tekstikasti',
  publish_your_text_exercise_description: 'Ülesande kirjeldus',
  publish_your_text_submit_button: 'Loovuta tekst',
  publish_your_text_terms_of_service_1: 'Luban kasutada oma teksti ja taustaandmeid õppejõududel ja üliõpilastel, õpetajatel ja õppijatel, teadustöötajatel ja tarkvaraarendajatel erialase töö ning keeleõppe vajadustel.',
  publish_your_text_terms_of_service_2: 'Isikuandmed on eemaldatud, autorit pole andmebaasis märgitud. Kui korpusesse soovitakse lisada avalikult kättesaadav tekst, siis tuleb järgida väljaande litsentsitingimusi. Kõik autoriõigused on tagatud.',
  publish_your_text_terms_of_service_infobox_1: 'Teksti loovutades nõustun ',
  publish_your_text_terms_of_service_infobox_2: 'ELLE kasutustingimustega',
  publish_your_text_terms_of_service_title: 'ELLE kasutustingimused',
  publish_your_text_text_data_academic_category: 'Kategooria',
  publish_your_text_text_data_academic_category_academic_studies: 'Erialaõpingud',
  publish_your_text_text_data_academic_category_research: 'Uurimused',
  publish_your_text_text_data_main_text_type: 'Põhiliik',
  publish_your_text_text_data_main_text_type_academic: 'Akadeemiline',
  publish_your_text_text_data_main_text_type_non_academic: 'Mitteakadeemiline',
  publish_your_text_text_data_number: 'Number',
  publish_your_text_text_data_pages: 'Leheküjed',
  publish_your_text_text_data_publication: 'Väljaanne',
  publish_your_text_text_data_sub_text_type: 'Alamliik',
  publish_your_text_text_data_supporting_material: 'Õppe- või abimaterjal',
  publish_your_text_text_data_supporting_material_other: 'Muu õppe- või abimaterjal',
  publish_your_text_text_data_year: 'Aasta',
  publish_your_text_title: 'Pealkiri',
  query_author_data_age: 'Vanus',
  query_author_data_age_over_40: 'üle 40',
  query_author_data_age_up_to_18: 'kuni 18',
  query_author_data_country: 'Elukohariik',
  query_author_data_country_other: 'Muu elukohariik',
  query_author_data_degree: 'Teaduskraad',
  query_author_data_degree_bachelors: 'Bakalaureusekraad',
  query_author_data_degree_doctoral: 'Doktorikraad',
  query_author_data_degree_masters: 'Magistrikraad',
  query_author_data_education: 'Haridus',
  query_author_data_education_elementary: 'algharidus/põhiharidus',
  query_author_data_education_higher: 'kõrgharidus',
  query_author_data_education_secondary: 'keskharidus',
  query_author_data_education_vocational: 'keskeriharidus/kutseharidus',
  query_author_data_gender: 'Sugu',
  query_author_data_gender_female: 'naine',
  query_author_data_gender_male: 'mees',
  query_author_data_level_of_study: 'Õppeaste',
  query_author_data_level_of_study_bachelors_studies: 'Bakalaureuseõpe',
  query_author_data_level_of_study_doctoral_studies: 'Doktoriõpe',
  query_author_data_level_of_study_masters_studies: 'Magistriõpe',
  query_author_data_nationality: 'Kodakondsus',
  query_author_data_native_language: 'Emakeel',
  query_author_data_other_languages: 'Muu õppe-, töö- või suhtluskeel',
  query_author_data_other_languages_plural: 'Muud õppe-, töö- või suhtluskeeled',
  query_change_chosen_corpuses: 'Muuda otsingut',
  query_choose_texts: 'Otsi tekste',
  query_common_country_de: 'Saksamaa',
  query_common_country_en: 'Inglismaa',
  query_common_country_et: 'Eesti',
  query_common_country_fi: 'Soome',
  query_common_country_hu: 'Ungari',
  query_common_country_lt: 'Leedu',
  query_common_country_lv: 'Läti',
  query_common_country_other: 'Muu',
  query_common_country_ru: 'Venemaa',
  query_common_country_se: 'Rootsi',
  query_common_language_by: 'valgevene',
  query_common_language_cn: 'hiina',
  query_common_language_de: 'saksa',
  query_common_language_en: 'inglise',
  query_common_language_et: 'eesti',
  query_common_language_et_ru: 'eesti, vene',
  query_common_language_fi: 'soome',
  query_common_language_fr: 'prantsuse',
  query_common_language_hu: 'ungari',
  query_common_language_ji: 'jidiš',
  query_common_language_jp: 'jaapani',
  query_common_language_lt: 'leedu',
  query_common_language_lv: 'läti',
  query_common_language_pl: 'poola',
  query_common_language_ru: 'vene',
  query_common_language_se: 'rootsi',
  query_common_language_ua: 'ukraina',
  query_common_nationality_bg: 'Bulgaaria',
  query_common_nationality_br: 'Brasiilia',
  query_common_nationality_by: 'Valgevene',
  query_common_nationality_cn: 'Hiina Rahvavabariik',
  query_common_nationality_de: 'Saksamaa',
  query_common_nationality_eg: 'Egiptus',
  query_common_nationality_es: 'Hispaania',
  query_common_nationality_et: 'Eesti',
  query_common_nationality_fi: 'Soome',
  query_common_nationality_fr: 'Prantsusmaa',
  query_common_nationality_gb: 'Suurbritannia',
  query_common_nationality_gr: 'Kreeka',
  query_common_nationality_ie: 'Iirimaa',
  query_common_nationality_in: 'India',
  query_common_nationality_lt: 'Leedu',
  query_common_nationality_lv: 'Läti',
  query_common_nationality_mk: 'Põhja-Makedoonia',
  query_common_nationality_nl: 'Holland',
  query_common_nationality_ph: 'Filipiinid',
  query_common_nationality_pl: 'Poola',
  query_common_nationality_ro: 'Rumeenia',
  query_common_nationality_ru: 'Venemaa',
  query_common_nationality_tr: 'Türgi',
  query_common_nationality_ua: 'Ukraina',
  query_common_nationality_unassigned: 'Määramata',
  query_common_nationality_us: 'Ameerika Ühendriigid',
  query_common_nationality_ve: 'Venezuela',
  query_download_basictext: 'Lihttekst',
  query_download_form: 'Vorming',
  query_download_license: 'Litsents:',
  query_download_single_file: 'Ühe failina',
  query_download_stanza: 'Süntaktiline märgendus – CoNLL-U',
  query_download_tei: 'TEI-märgendus',
  query_download_vislcg3: 'Süntaktiline märgendus – VISLCG3',
  query_download_zip: 'ZIP failina',
  query_filters: 'Otsingu filtrid',
  query_own_texts: 'Oma tekstid',
  query_results_accordion_title: 'Otsingu tulemused',
  query_results_found_texts: 'Leitud tekste:',
  query_results_no_texts_found: 'Ei leitud ühtegi teksti.',
  query_results_preview_metadata_modal_title: 'Teksti metainfo',
  query_results_save_texts_for_analysis: 'Salvesta tekstid analüüsiks',
  query_results_saved_for_analysis: 'Analüüsiks salvestatud tekstid:',
  query_results_saved_for_analysis_corpus: 'korpuse tekst',
  query_results_saved_for_analysis_corpus_plural: 'korpuse teksti',
  query_results_saved_for_analysis_own_texts: 'oma tekstid',
  query_results_select_all: 'Vali kõik',
  query_results_unselect_all: 'Eemalda kõik',
  query_subcoprus_L2_olympiade: 'K2 olümpiaaditööd',
  query_subcoprus_L2_olympiade_hover: 'Sisaldab eesti keele kui teise keele olümpiaadi loovkirjutisi.',
  query_subcorpus: 'Alamkorpus',
  query_subcorpus_L1_estonian: 'K1 eesti keel',
  query_subcorpus_L1_estonian_hover: 'Võrdluskorpus, mis sisaldab peamiselt emakeelekõnelejate arvamuslugusid ajalehtedest.',
  query_subcorpus_L1_russian: 'K1 vene keel',
  query_subcorpus_L1_russian_hover: 'Sisaldab põhikooli ja gümnaasiumi vene emakeelega õpilaste loomingulist laadi tekste.',
  query_subcorpus_L2_estonian: 'K2 keeleõpe',
  query_subcorpus_L2_estonian_hover: 'Sisaldab eesti keelt teise keelena ja võõrkeelena õppijate eksamiväliselt kirjutatud tekste, millest osa on saanud tasemehinnangu (A1-C2) kahe või kolme tunnustatud hindamise spetsialisti arvamuse põhjal.',
  query_subcorpus_L2_proficiency_examinations: 'K2 riiklikud eksamitööd',
  query_subcorpus_L2_proficiency_examinations_hover: 'Sisaldab eesti keele kui teise keele riiklikke eksami- ja tasemetöid.',
  query_subcorpus_L3_russian: 'K3 vene keel',
  query_subcorpus_L3_russian_hover: 'Sisaldab tekste eesti emakeelega õpilastelt, kes õpivad koolis vene keelt kolmanda keelena.',
  query_subcorpus_academic_estonian: 'Akadeemiline eesti keel',
  query_subcorpus_academic_estonian_hover: 'Sisaldab eesti emakeelega ja eesti keelt teise keelena kasutavate üliöpilaste akadeemilise keelekasutuse näiteid (referaadid, seminaritööd, lõputööd jm).',
  query_subcorpus_all: 'kõik',
  query_text_data_char_over_5000: 'üle 5000',
  query_text_data_char_up_to_500: 'kuni 500',
  query_text_data_characters: 'Tähemärke',
  query_text_data_language: 'Keel',
  query_text_data_level: 'Tase',
  query_text_data_material_selected_plural: 'Valitud {{amount}} materjali',
  query_text_data_over: 'üle',
  query_text_data_sentences_over_100: 'üle 100',
  query_text_data_sentences_up_to_10: 'kuni 10',
  query_text_data_type: 'Liik',
  query_text_data_type_L1_examination: 'K1 eksamitöö',
  query_text_data_type_L1_exercise: 'K1 harjutus',
  query_text_data_type_L1_opinion_piece: 'K1 arvamuslugu',
  query_text_data_type_L1_russian_creative_writing: 'K1 vene loovkirjutis',
  query_text_data_type_L1_russian_examination: 'K1 vene eksamitöö',
  query_text_data_type_L2_creative_writing: 'K2 loovkirjutis',
  query_text_data_type_L2_examination: 'K2 eksamitöö',
  query_text_data_type_L2_exercise: 'K2 harjutus',
  query_text_data_type_L2_exercise_ad: 'Reklaam',
  query_text_data_type_L2_exercise_ad_full: 'K2 harjutus: Reklaam',
  query_text_data_type_L2_exercise_announcement: 'Kuulutus',
  query_text_data_type_L2_exercise_announcement_full: 'K2 harjutus: kuulutus',
  query_text_data_type_L2_exercise_answering_questions: 'Vastused küsimustele',
  query_text_data_type_L2_exercise_answering_questions_full: 'K2 harjutus: vastused küsimustele',
  query_text_data_type_L2_exercise_contract: 'Leping',
  query_text_data_type_L2_exercise_contract_full: 'K2 harjutus: leping',
  query_text_data_type_L2_exercise_description: 'Kirjeldus',
  query_text_data_type_L2_exercise_description_full: 'K2 harjutus: kirjeldus',
  query_text_data_type_L2_exercise_dialogue: 'Dialoog',
  query_text_data_type_L2_exercise_dialogue_full: 'K2 harjutus: dialoog',
  query_text_data_type_L2_exercise_dictation: 'Etteütlus',
  query_text_data_type_L2_exercise_dictation_full: 'K2 harjutus: etteütlus',
  query_text_data_type_L2_exercise_fill_in_the_blanks: 'Lünktekst',
  query_text_data_type_L2_exercise_fill_in_the_blanks_full: 'K2 harjutus: lünktekst',
  query_text_data_type_L2_exercise_guide: 'Teejuht',
  query_text_data_type_L2_exercise_guide_full: 'K2 harjutus: teejuht',
  query_text_data_type_L2_exercise_instruction: 'Juhend',
  query_text_data_type_L2_exercise_instruction_full: 'K2 harjutus: juhend',
  query_text_data_type_L2_exercise_menu: 'Menüü',
  query_text_data_type_L2_exercise_menu_full: 'K2 harjutus: menüü',
  query_text_data_type_L2_exercise_recipe: 'Retsept',
  query_text_data_type_L2_exercise_recipe_full: 'K2 harjutus: retsept',
  query_text_data_type_L2_exercise_retelling: 'Ümberjutustus',
  query_text_data_type_L2_exercise_retelling_full: 'K2 harjutus: ümberjutustus',
  query_text_data_type_L2_exercise_sentence_construction: 'Laused',
  query_text_data_type_L2_exercise_sentence_construction_full: 'K2 harjutus: laused',
  query_text_data_type_L2_exercise_speech: 'Kõne',
  query_text_data_type_L2_exercise_speech_full: 'K2 harjutus: kõne',
  query_text_data_type_L2_letter: 'K2 kiri',
  query_text_data_type_L2_letter_informal: 'Isiklik',
  query_text_data_type_L2_letter_informal_full: 'K2 kiri: isiklik',
  query_text_data_type_L2_letter_semi_formal: '(Pool)ametlik',
  query_text_data_type_L2_letter_semi_formal_full: 'K2 kiri: (pool)ametlik',
  query_text_data_type_L2_test: 'K2 kontrolltöö/test',
  query_text_data_type_L2_translation: 'K2 tõlge',
  query_text_data_type_L3_russian_creative_writing: 'K3 vene loovkirjutis',
  query_text_data_type_L3_russian_examination: 'K3 vene eksamitöö',
  query_text_data_type_academic_research: 'AK uurimused',
  query_text_data_type_academic_research_bachelors_thesis: 'Bakalaureusetöö',
  query_text_data_type_academic_research_bachelors_thesis_full: 'AK uurimused: bakalaureusetöö',
  query_text_data_type_academic_research_doctoral_thesis: 'Doktoritöö',
  query_text_data_type_academic_research_doctoral_thesis_full: 'AK uurimused: doktoritöö',
  query_text_data_type_academic_research_masters_thesis: 'Magistritöö',
  query_text_data_type_academic_research_masters_thesis_full: 'AK uurimused: magistritöö',
  query_text_data_type_academic_research_presentation: 'Ettekanne',
  query_text_data_type_academic_research_presentation_full: 'AK uurimused: ettekanne',
  query_text_data_type_academic_research_research_article: 'Artikkel',
  query_text_data_type_academic_research_research_article_full: 'AK uurimused: artikkel',
  query_text_data_type_academic_research_summary: 'Kokkuvõte',
  query_text_data_type_academic_research_summary_full: 'AK uurimused: kokkuvõte',
  query_text_data_type_academic_research_thesis: 'Diplomitöö',
  query_text_data_type_academic_research_thesis_full: 'AK uurimused: diplomitöö',
  query_text_data_type_academic_studies: 'AK erialaõpingud',
  query_text_data_type_academic_studies_analysis: 'Analüüs',
  query_text_data_type_academic_studies_analysis_full: 'AK erialaõpingud: analüüs',
  query_text_data_type_academic_studies_course_paper: 'Kursusetöö',
  query_text_data_type_academic_studies_course_paper_full: 'AK erialaõpingud: kursusetöö',
  query_text_data_type_academic_studies_essay: 'Essee',
  query_text_data_type_academic_studies_essay_full: 'AK erialaõpingud: essee',
  query_text_data_type_academic_studies_overview: 'Ülevaade',
  query_text_data_type_academic_studies_overview_full: 'AK erialaõpingud: ülevaade',
  query_text_data_type_academic_studies_report: 'Referaat',
  query_text_data_type_academic_studies_report_full: 'AK erialaõpingud: referaat',
  query_text_data_type_academic_studies_review: 'Retsensioon',
  query_text_data_type_academic_studies_review_full: 'AK erialaõpingud: retsensioon',
  query_text_data_type_academic_studies_seminar_paper: 'Seminaritöö',
  query_text_data_type_academic_studies_seminar_paper_full: 'AK erialaõpingud: seminaritöö',
  query_text_data_type_selected_plural: 'Valitud {{amount}} liiki',
  query_text_data_up_to: 'kuni',
  query_text_data_used_study_or_supporting_materials: 'Kasutatud õppe- või abimaterjale',
  query_text_data_used_study_or_supporting_materials_automated_correction: 'Automaatkontroll',
  query_text_data_used_study_or_supporting_materials_monolingual_dictionary: 'Ükskeelne sõnastik (k.a. veebisõnastikud)',
  query_text_data_used_study_or_supporting_materials_no: 'Ei',
  query_text_data_used_study_or_supporting_materials_professional_handbook: 'Erialane käsiraamat või teatmik',
  query_text_data_used_study_or_supporting_materials_term_glossary_or_base: 'Erialane terminisõnastik või -baas',
  query_text_data_used_study_or_supporting_materials_translation_dictionary_or_machine_translation: 'Tõlkesõnastik või masintõlge',
  query_text_data_used_study_or_supporting_materials_yes: 'Jah',
  query_text_data_used_supporting_materials: 'Kasutatud õppematerjale',
  query_text_data_used_supporting_materials_no: 'ei',
  query_text_data_used_supporting_materials_yes: 'jah',
  query_text_data_words_over_800: 'üle 800',
  query_text_data_words_up_to_100: 'kuni 100',
  query_text_data_year_of_publication: 'Lisamise aasta',
  restore_data_button: 'Ennista andmed',
  send_request_button: 'Saada päring',
  server_offline_page_error: 'ELLE teenused ei ole hetkel kättesaadavad. Palun proovi hiljem uuesti.',
  session_expiration_modal_content_1: 'Sinu sessioon aegub vähem kui {{minutesLeft}} {{unit}} pärast.',
  session_expiration_modal_content_2: 'Kas soovid sessiooni pikendada?',
  session_expiration_modal_renew_no: 'Ei',
  session_expiration_modal_renew_yes: 'Jah',
  session_expiration_modal_title: 'Sessioon aegub',
  session_expiration_modal_unit_plural: 'minuti',
  session_expiration_modal_unit_singular: 'minuti',
  start_button: 'Alusta',
  success_generic: 'Toiming õnnestus.',
  success_logout: 'Oled edukalt välja logitud.',
  success_logout_forced: 'Sessiooni aegumise tõttu logiti sind automaatselt välja.',
  success_session_renew: 'Sessiooni pikendamine õnnestus.',
  syllables_beginning: 'algus',
  syllables_end: 'lõpp',
  syllables_filename: 'silbid',
  syllables_header_location: 'Silbi asukoht sõnas',
  syllables_header_syllable: 'Silp',
  syllables_middle: 'keskel',
  syllables_table_beginning: 'Asukoht (algus)',
  syllables_table_end: 'Asukoht (keskel)',
  syllables_table_middle: 'Asukoht (lõpp)',
  tab_gram_anal: 'Grammatiline analüüs',
  table_click_tutorial_text: 'Tabelis olevatel väärtustel klõpsates märgitakse ülevaloleval tekstikastis kõik sõnad, mis on valikuga seotud.',
  table_tab_tutorial_text: 'Siit saad valida, mis infot sa allolevates tabelites näha soovid.',
  table_tutorial_text: 'Siin tabelis näed infot tekstikastis olevate sõnade kohta.',
  text_analysis: 'Tekstianalüüs',
  textupload_primary_modal_save: 'Salvesta tekstid analüüsiks',
  textupload_primary_modal_title: 'Sisesta või laadi üles oma tekstid',
  textupload_secondary_modal_choose_files: 'Vali failid',
  textupload_secondary_modal_chosen_files: 'Valitud failid:',
  textupload_secondary_modal_title: 'Vali tekst',
  textupload_secondary_modal_tooltip: 'Laadi üles oma tekstid',
  textupload_secondary_modal_upload: 'Laadi failid üles',
  tools_accordion_analysis: 'Analüüsivahendid',
  tools_accordion_analysis_infobox: 'Hallide tööriistade kasutamiseks vali või lisa esmalt tekst(id). Ülejäänud tööriistadesse ei saa veel tekste suunata: sisesta need tekstikasti või laadi üles.',
  tools_accordion_clusters_explainer: 'Mustrid ehk n-grammid aitavad tekstist leida tüüpilisemad sõnajärjendid',
  tools_accordion_neighbouring_words_explainer: 'Leia sõnad, mis esinevad tekstis sageli koos',
  tools_accordion_word_analysis_explainer: 'Leia sõnade silbid, algvormid ja grammatilised vormid',
  tools_accordion_word_in_context_explainer: 'Vaata sõna ümbrust tekstis, et saada parem ülevaade selle kasutusest',
  tools_accordion_wordlist_explainer: 'Kuva tekstis kasutatud sõnad sageduse või tähestiku järjekorras',
  tools_infobox: 'Otsi tekste EVKK tekstikogust ja analüüsi neid meie tööriistadega või sisesta analüüsimiseks oma tekst(id).',
  tools_title: 'ELLE tööriistad',
  tools_video_text: 'Vaata tutvustavat videot',
  tools_warning_text: 'Tööriista kasutamiseks tuleb vasakult menüüst valida analüüsitav tekst või tekstid.',
  try_again: 'Proovi uuesti',
  word_analyser_text_too_long_infobox: 'Teksti analüüsimine sõna kaupa on võimalik kuni 1000-sõnalise teksti puhul.',
  word_analyser_word_with_foreign_characters_warning: 'Sõna sisaldab võõraid tähemärke.',
  wordcontext_filename: 'sõnakontekstis',
  wordinfo_tutorial_text: 'Sõnadel klõpsates ilmub siia valitud sõna grammatiline info.',
  wordinfo_word: 'Sõna',
  wordlist_exclude_stopwords: 'Välista stoppsõnad',
  wordlist_filename: 'sõnaloend',
  wordlist_lemma_column: 'Algvorm',
  wordlist_retain_uppercase_letters: 'säilita suurtähed',
  wordlist_retain_uppercase_letters_hover: 'Sõnad muudetakse vaikimisi väiketäheliseks, näiteks "kool" ja "Kool" loetakse samaks sõnaks. Märgi kasti linnuke, kui soovid, et suur- ja väiketähelisi sõnu arvestataks eraldi (nt "Eesti" ja "eesti").',
  wordlist_search_base_forms: 'algvormid',
  wordlist_search_word_forms: 'sõnavormid',
  wordlist_set_minimum_word_frequency: 'Määra sõna minimaalne sagedus',
  wordlist_set_minimum_word_frequency_hover: 'Kui soovid näiteks välistada sõnad, mida esineb tekstis vaid üks kord, siis määra sageduse alampiiriks 2. Mahukamaid tekstikogusid analüüsides jäetakse sageli kõrvale alla 5 korra esinevad sõnad.',
  wordlist_stopwords_from_the_default_list: 'vaikimisi loendist',
  wordlist_stopwords_textbox: 'Kirjuta siia oma stoppsõnad (nt koer, kodu)',
  wordlist_stopwords_textbox_hover_1: 'Eesti keele stoppsõnade loendi on koostanud Kristel Uiboaed. See sisaldab sidesõnu, asesõnu, sisutühje tegusõnu ja määrsõnu. Nimekiri on kättesaadav Tartu Ülikooli andmerepositooriumis (vaata ',
  wordlist_stopwords_textbox_hover_2: 'siit',
  wordlist_stopwords_textbox_hover_3: ').',
  wordlist_word_column: 'Sõnavorm',
  wordlist_wordcloud: 'Sõnapilv',
  wordlist_wordcloud_filename: 'sonapilv',
  wordlist_wordcloud_loading: 'Laadin sõnapilve...'
};
