export const WORKER_IMAGES = {
  pille: require('./pille.png').default,
  harli: require('./harli.png').default,
  jaagup: require('./jaagup.png').default,
  kais: require('./kais.png').default,
  kaisa: require('./kaisa.png').default,
  karina: require('./karina.png').default,
  mikk: require('./mikk.png').default,
  silvia: require('./silvia.png').default,
  taavi: require('./taavi.png').default
};
